export default {
  test: '測試測試測試',
  // langs
  tw: '繁體中文',
  jp: '日本語',
  en: 'English',
  cn: '简体中文',
  // navbar
  gamePool: '彩金池',
  buyEGT: '购买 EGT',
  sellEGT: '兑换 ETH',
  swapEGT: '兑换 ETH-EGT',
  calculator: 'EGT 换算器',
  community: '我的社区',
  // home
  homeTitle: '智能合约借贷交易 轻松活用数位资产',
  homeSubtitle: '开启不一样的投资人生',
  toSwap: '立即前往TBT SWAP',
  successfullyBorrowAmount: '已成功贷款金额',
  borrowMarket: '{token} 借贷市场',
  title: ['EGT Defi', '借贷交易平台'],
  // index
  indexTitle: '智能合约借贷商业应用',
  indexSubtitle: '智能合约．去中心化．以太连动． P2P借贷',
  indexDesc: 'EDT为完全去中心的区块链P2P借贷商业应用，结合智能合约、以太币连动，以达公平、公正、公开，让所有人都能安心、放心、随心的参与投资及借贷。 ',
  investAmount: '已贷款金额',
  gamePoolAmount: '彩金池金额',
  login: '登入',
  logout: '登出',
  // 贷款
  toBorrow: '我要贷款',
  borrowRule: '贷款规则',
  borrowRuleText: [
    '贷款人欲贷款前须先将抵押品存放于区块链钱包中，尔后填写贷款单提出贷款申请。 ',
    '成功提出贷款申请后抵押品会被转入智能合约保管，并于「我的贷款」及 「投资清单」中看到该贷款合约，尔后等待媒合，媒合成功前贷款人可随时修改贷款内容。 ',
    '媒合成功后，贷款人的区块链钱包会收到扣除利息后之贷款金额，并于「我的贷款」中看到该贷款合约。 ',
    '贷款人于该贷款合约时间到期前可随时还款，成功还款后智能合约会将抵押品自动归还于贷款人的区块链钱包。 ',
    '贷款人若于该贷款合约时间到期时未还款，则抵押品会被智能合约没收，其所有权将归该贷款合约之投资人所有。 ',
    '上述操作所产生之BNB矿工费须由贷款人自行负担。 '
  ],
  myLoans: '我的贷款',
  createLoan: '申请贷款',
  // 投资
  toDeposit: '我要投资',
  depositRule: '投资规则',
  depositRuleText_usdt: [
    '投资人欲投资前须先将USDT存放于区块链钱包中，尔后于「投资清单」中搜索投资合约并申请投资。 ',
    '成功投资后USDT会自动贷款给贷款人，并于「我的投资」中看到该投资合约。 ',
    '该投资合约时间到期前，若贷款人进行还款，借出之款项及利息会自动转入投资人的区块链钱包中。 ',
    '该投资合约时间到期时，若贷款人未进行还款，则抵押品90%归投资人、10%归平台方，投资人可于该投资合约中提出收回抵押品及利息。 ',
    '上述操作之BNB矿工费须投资人自行负担。 '
  ],
  depositRuleText_tbt: [
    '投资人欲投资前须先将TBT存放于区块链钱包中，尔后于「投资清单」中搜索投资合约并申请投资。 ',
    '成功投资后TBT会自动贷款给贷款人，并于「我的投资」中看到该投资合约。 ',
    '该投资合约时间到期前，若贷款人进行还款，借出之款项及利息会自动转入投资人的区块链钱包中。 ',
    '该投资合约时间到期时，若贷款人未进行还款，则抵押品90%归投资人、10%归平台方，投资人可于该投资合约中提出收回抵押品及利息。 ',
    '上述操作之BNB矿工费须投资人自行负担。 '
  ],
  myDeposit: '我的投资',
  orderList: '投资清单',
  // defi registry
  registryBorrow: '贷款前须先注册',
  registryDeposit: '投资前须先注册',
  enterReferer: '请输入推荐地址',
  // egt
  enterEthAmount: '输入 ETH 数量',
  enterExchangeEthAmount: '请输入欲兑换的 ETH 数量',
  enterEgtAmount: '输入 EGT 数量',
  enterExchangeEgtAmount: '请输入欲兑换的 EGT 数量',
  enterBSCEgtAmount: '输入 BSC-EGT 数量',
  canChange: '可兑换成',
  piece: '颗',
  pieceBack: '',
  buy: '购买',
  egtExchangeRate: '({value} 兑换)',
  registryEgt: '购买前须先注册',
  registry: '注册',
  sellApprove: '兑换前请先解锁',
  sell: '兑换',
  sendBscEGT: 'ETH-EGT 发放',
  // bridge
  userAddress: '使用者地址',
  enterBridgeAmount: '请输入欲发放的 ETH-EGT 数量',
  sendToken: '发放',
  // game
  luckyPool: '幸运彩金池',
  countdown: '开奖倒数',
  noOrder: '目前尚无订单',
  drawing: '开奖中',
  currRound: '目前回合',
  newLuckyAddress: '最新幸运地址',
  luckyPoolRule: '幸运彩金池规则',
  luckyPoolRuleText: [
    '回合结束时间以上方「开奖倒数」为准，累计幸运彩金与开奖倒数时间连带关系如下方「彩金池级距表」为准，即有新贷款合约成立，开奖倒数会依累计幸运彩金的对应时间重新计算，直至开奖倒数结束时无新贷款合约成立则该回合结束，幸运地址独得所有幸运彩金。 ',
    '幸运地址：开奖倒数结束时，最后一个成功媒合贷款合约之贷款人。 \nEx.假设累计幸运彩金超过100,000，对应时间为10mins，若A成功媒合贷款合约(不限贷款金额及支付利息)，A即为最新幸运地址，开奖倒数会由10mins开始，当倒数结束时无新成功媒合贷款合约之贷款人，A即为幸运地址，可独得幸运彩金。若倒数未结束时，B成功媒合贷款合约，则开奖倒数会重新由10mins开始，且B即替代A的身分，而B是否成为幸运帐户则如上述。 '
  ],
  stepTable: '彩金池级距表',
  time: '时间',
  blockchainBased: '以区块链时间为主',
  accumPoolAmount: '累积彩金',
  rankPool: '竞赛彩金池',
  myRankAmount: '我的竞赛金额',
  rankPoolRule: '竞赛彩金池规则',
  rankPoolRuleText_usdt: [
    '竞赛彩金池以三十天为一回合，回合结束时间以上方「开奖倒数」为准，回合结束时竞赛金额最高者独得竞赛彩金池50%、竞赛金额2-50名依比重分得竞赛彩金池50%。 ',
    '竞赛金额：同一回合中申请贷款，并成功媒合后所支付之利息累计，其金额无法继承至下一回合。 \nEx.A于同一回合中，第一次申请贷款且成功媒合所支付利息为100USDT、第二次申请贷款且成功媒合所支付利息为200USDT，则A于该回合中竞赛金额则为100+200=300，若该回合结束时A为竞赛金额最高者，则可独得竞赛彩金池50%。若该回合结束时A为竞赛金额2-50名，则可依比重分得竞赛彩金池50%。 '
  ],
  rankPoolRuleText_tbt: [
    '竞赛彩金池以三十天为一回合，回合结束时间以上方「开奖倒数」为准，回合结束时竞赛金额最高者独得竞赛彩金池50%、竞赛金额2-50名依比重分得竞赛彩金池50%。 ',
    '竞赛金额：同一回合中申请贷款，并成功媒合后所支付之利息累计，其金额无法继承至下一回合。 \nEx.A于同一回合中，第一次申请贷款且成功媒合所支付利息为100TBT、第二次申请贷款且成功媒合所支付利息为200TBT，则A于该回合中竞赛金额则为100+200=300，若该回合结束时A为竞赛金额最高者，则可独得竞赛彩金池50%。若该回合结束时A为竞赛金额2-50名，则可依比重分得竞赛彩金池50%。 '
  ],
  rank: '名次',
  address: '地址',
  rankAmount: '金额',
  // other
  yourAddress: '您的收款地址',
  backToIndex: '返回首页',
  gasNowEstimate: '目前矿工费预估',
  gasWarning: '(此矿工费预估仅为参考，实际费用以合约执行消耗为准)',
  priceUpdated: '价格更新于',
  filter: '筛选',
  id: '编号',
  loanDays: '贷款天数',
  loanToken: '抵押币种',
  loanTokenAmount: '抵押数量',
  loanAmount: '贷款金额',
  loanMortgage: '贷款成数',
  loanRate: '利率',
  loanInterest: '利息',
  marketValue: '市值',
  APR: '年化报酬率',
  waiting: '待媒合',
  repay: '已还款',
  breach: '违约',
  isCancel: '取消',
  loaning: '贷款中',
  approve: '申请前请先解锁',
  more: '大于',
  less: '小于',
  invest: '投资',
  noRecord: '无相关纪录',
  day: '天',
  hour: '时',
  min: '分',
  sec: '秒',
  contract: '合约',
  expired: '过期',
  buffer: '缓冲期',
  due: '到期',
  status: '合约状态',
  withdraw: '收回',
  edit: '编辑',
  cancel: '取消',
  payback: '还款',
  apply: '申请',
  editWarning: '编辑将会【取消】该笔订单并再次【建立】新订单',
  editSubWarning: '(取消及建立皆须支付矿工费)',
  // calculator
  swapCalculateTitle: '{token1} 兑 {token2}',
  change: '兑换',
  amount: '数量',
  dollar: '元',
  dataUpdated: '资料更新于',
  calculate: '计算',
  // community
  refererAmount: '我的直推人数',
  communityAmount: '我的社区人数',
  // ADT
  adt: 'ADT 天使分润',
  holding: '持有量',
  claimable: '可领量',
  claim: '领取',
  // gas fee
  gasPage: 'Gas 补助',
  date: '日期',
  from: '起',
  to: '讫',
  applyAddress: '申请人地址',
  search: '搜寻',
  total: '总计',
  subsidy: '补贴',
  subsidyTotal: '补助总额',
  send: '送出',
  // warning
  warning: '重要提示',
  warningRegistry: '1. 进行操作前请先注册，若未注册会导致交易失败。 ',
  warningBorrow: '2. 贷款清单中抵押品市值显示仅供参考，借贷双方不可做为决策时的唯一依据，本平台不负责吿知抵押品正确市值之责任！ ',
  warningDeposit: '2. 投资清单中抵押品市值显示仅供参考，借贷双方不可做为决策时的唯一依据，本平台不负责吿知抵押品正确市值之责任！ ',
  warningCalculate: '该抵押品之市值，请于「EGT换算器」中计算。 ',
  toRegistry: '前往注册',
  toCalculator: '前往 EGT 换算器',
  agree: '我已了解上述说明，同意打勾。 ',
  confirm: '确认',
  // toasted
  loginFirst: '请先登入',
  installMetamask: '请安装 MetaMask',
  changeMainnet: '请切换到币安智能链',
  changeETH: '请切换至以太主网',
  connectionBreak: 'MetaMask 连接中断，请重新登入',
  changeWallet: '已切换连接钱包',
  waitApprove: '解锁中，请稍后',
  waitRegistry: '注册中，请稍后',
  waitClaim: '提取中，请稍后',
  txSend: '交易已送出',
  userRefuse: '使用者拒绝连线',
  addressHasUsed: '此地址已注册',
  errorOccured: '发生错误',
  USDTUnderBalance: 'USDT 余额不足',
  TBTUnderBalance: 'TBT 余额不足',
  underBalance: '余额不足',
  gameOpening: '彩金池开奖中',
  cannotGetGas: '无法计算矿工费',
  renewGetGas: 'WebSocket 连线中断，请重整网页以获取最新的预估矿工费用',
  renew: 'WebSocket 连线中断，请重整网页',
  waitGetData: '正在从链上获取资讯，请稍后',
  noReward: '尚无收益可领取',
  cannotGetEthValue: '取得 ETH 市值失败',
  cannotGetData: '取得资料失败',
  selectTx: '请选择要补助之交易',
  txError: '交易发生错误',
  agreeFirst: '请勾选同意',
  cannotGetMarketValue: '无法计算市值',
  // other
  cannotFindPage: '找不到网页',
  webConstructing: '网站建置中',
  webConstructingDescription: '很抱歉您所造访的网页正在建置中，功能尚未开放',
}