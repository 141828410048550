export default {
  test: '測試測試測試',
  // langs
  tw: '繁體中文',
  jp: '日本語',
  en: 'English',
  cn: '简体中文',
  // navbar
  gamePool: '彩金池',
  buyEGT: '購買 EGT',
  sellEGT: '兌換 ETH',
  swapEGT: '兌換 ETH-EGT',
  calculator: 'EGT 換算器',
  community: '我的社區',
  // home
  homeTitle: '智能合約借貸交易 輕鬆活用數位資產',
  homeSubtitle: '開啟不一樣的投資人生',
  successfullyBorrowAmount: '已成功貸款金額',
  borrowMarket: '{token} 借貸市場',
  title: ['EGT Defi', '借貸交易平台'],
  // index
  indexTitle: '智能合約借貸商業應用',
  indexSubtitle: '智能合約．去中心化．以太連動．P2P借貸',
  indexDesc: 'EDT為完全去中心的區塊鏈P2P借貸商業應用，結合智能合約、以太幣連動，以達公平、公正、公開，讓所有人都能安心、放心、隨心的參與投資及借貸。',
  investAmount: '已貸款金額',
  gamePoolAmount: '彩金池金額',
  login: '登入',
  logout: '登出',
  // 貸款
  toBorrow: '我要貸款',
  borrowRule: '貸款規則',
  borrowRuleText: [
    '貸款人欲貸款前須先將抵押品存放於區塊鏈錢包中，爾後填寫貸款單提出貸款申請。',
    '成功提出貸款申請後抵押品會被轉入智能合約保管，並於「我的貸款」及 「投資清單」中看到該貸款合約，爾後等待媒合，媒合成功前貸款人可隨時修改貸款內容。',
    '媒合成功後，貸款人的區塊鏈錢包會收到扣除利息後之貸款金額，並於「我的貸款」中看到該貸款合約。',
    '貸款人於該貸款合約時間到期前可隨時還款，成功還款後智能合約會將抵押品自動歸還於貸款人的區塊鏈錢包。',
    '貸款人若於該貸款合約時間到期時未還款，則抵押品會被智能合約沒收，其所有權將歸該貸款合約之投資人所有。',
    '上述操作所產生之BNB礦工費須由貸款人自行負擔。'
  ],
  myLoans: '我的貸款',
  createLoan: '申請貸款',
  // 投資
  toDeposit: '我要投資',
  depositRule: '投資規則',
  depositRuleText_usdt: [
    '投資人欲投資前須先將USDT存放於區塊鏈錢包中，爾後於「投資清單」中搜索投資合約並申請投資。',
    '成功投資後USDT會自動貸款給貸款人，並於「我的投資」中看到該投資合約。',
    '該投資合約時間到期前，若貸款人進行還款，借出之款項及利息會自動轉入投資人的區塊鏈錢包中。',
    '該投資合約時間到期時，若貸款人未進行還款，則抵押品90%歸投資人、10%歸平台方，投資人可於該投資合約中提出收回抵押品及利息。',
    '上述操作之BNB礦工費須投資人自行負擔。'
  ],
  depositRuleText_tbt: [
    '投資人欲投資前須先將TBT存放於區塊鏈錢包中，爾後於「投資清單」中搜索投資合約並申請投資。',
    '成功投資後TBT會自動貸款給貸款人，並於「我的投資」中看到該投資合約。',
    '該投資合約時間到期前，若貸款人進行還款，借出之款項及利息會自動轉入投資人的區塊鏈錢包中。',
    '該投資合約時間到期時，若貸款人未進行還款，則抵押品90%歸投資人、10%歸平台方，投資人可於該投資合約中提出收回抵押品及利息。',
    '上述操作之BNB礦工費須投資人自行負擔。'
  ],
  myDeposit: '我的投資',
  orderList: '投資清單',
  // defi registry
  registryBorrow: '貸款前須先註冊',
  registryDeposit: '投資前須先註冊',
  enterReferer: '請輸入推薦地址',
  // egt
  enterEthAmount: '輸入 ETH 數量',
  enterExchangeEthAmount: '請輸入欲兌換的 ETH 數量',
  enterEgtAmount: '輸入 EGT 數量',
  enterExchangeEgtAmount: '請輸入欲兌換的 EGT 數量',
  enterBSCEgtAmount: '輸入 BSC-EGT 數量',
  canChange: '可兌換成',
  piece: '顆',
  pieceBack: '',
  buy: '購買',
  egtExchangeRate: '({value} 兌換)',
  registryEgt: '購買前須先註冊',
  registry: '註冊',
  sellApprove: '兌換前請先解鎖',
  sell: '兌換',
  sendBscEGT: 'ETH-EGT 發放',
  // bridge
  userAddress: '使用者地址',
  enterBridgeAmount: '請輸入欲發放的 ETH-EGT 數量',
  sendToken: '發放',
  swapTitle: '我要兌換',
  tokenBalance: '持有數量',
  swapAmount: '兌換數量',
  tokenBalanceAmount: '{amount} 顆 {token}',
  // game
  luckyPool: '幸運彩金池',
  countdown: '開獎倒數',
  noOrder: '目前尚無訂單',
  drawing: '開獎中',
  currRound: '目前回合',
  newLuckyAddress: '最新幸運地址',
  luckyPoolRule: '幸運彩金池規則',
  luckyPoolRuleText: [
    '回合結束時間以上方「開獎倒數」為準，累計幸運彩金與開獎倒數時間連帶關係如下方「彩金池級距表」為準，即有新貸款合約成立，開獎倒數會依累計幸運彩金的對應時間重新計算，直至開獎倒數結束時無新貸款合約成立則該回合結束，幸運地址獨得所有幸運彩金。',
    '幸運地址：開獎倒數結束時，最後一個成功媒合貸款合約之貸款人。\nEx.假設累計幸運彩金超過100,000，對應時間為10mins，若A成功媒合貸款合約(不限貸款金額及支付利息)，A即為最新幸運地址，開獎倒數會由10mins開始，當倒數結束時無新成功媒合貸款合約之貸款人，A即為幸運地址，可獨得幸運彩金。若倒數未結束時，B成功媒合貸款合約，則開獎倒數會重新由10mins開始，且B即替代A的身分，而B是否成為幸運帳戶則如上述。'
  ],
  stepTable: '彩金池級距表',
  time: '時間',
  blockchainBased: '以區塊鏈時間為主',
  accumPoolAmount: '累積彩金',
  rankPool: '競賽彩金池',
  myRankAmount: '我的競賽金額',
  rankPoolRule: '競賽彩金池規則',
  rankPoolRuleText_usdt: [
    '競賽彩金池以三十天為一回合，回合結束時間以上方「開獎倒數」為準，回合結束時競賽金額最高者獨得競賽彩金池50%、競賽金額2-50名依比重分得競賽彩金池50%。',
    '競賽金額：同一回合中申請貸款，並成功媒合後所支付之利息累計，其金額無法繼承至下一回合。\nEx.A於同一回合中，第一次申請貸款且成功媒合所支付利息為100USDT、第二次申請貸款且成功媒合所支付利息為200USDT，則A於該回合中競賽金額則為100+200=300，若該回合結束時A為競賽金額最高者，則可獨得競賽彩金池50%。若該回合結束時A為競賽金額2-50名，則可依比重分得競賽彩金池50%。'
  ],
  rankPoolRuleText_tbt: [
    '競賽彩金池以三十天為一回合，回合結束時間以上方「開獎倒數」為準，回合結束時競賽金額最高者獨得競賽彩金池50%、競賽金額2-50名依比重分得競賽彩金池50%。',
    '競賽金額：同一回合中申請貸款，並成功媒合後所支付之利息累計，其金額無法繼承至下一回合。\nEx.A於同一回合中，第一次申請貸款且成功媒合所支付利息為100TBT、第二次申請貸款且成功媒合所支付利息為200TBT，則A於該回合中競賽金額則為100+200=300，若該回合結束時A為競賽金額最高者，則可獨得競賽彩金池50%。若該回合結束時A為競賽金額2-50名，則可依比重分得競賽彩金池50%。'
  ],
  rank: '名次',
  address: '地址',
  rankAmount: '金額',
  // other
  yourAddress: '您的收款地址',
  backToIndex: '返回首頁',
  gasNowEstimate: '目前礦工費預估',
  gasWarning: '(此礦工費預估僅為參考，實際費用以合約執行消耗為準)',
  priceUpdated: '價格更新於',
  filter: '篩選',
  id: '編號',
  loanDays: '貸款天數',
  loanToken: '抵押幣種',
  loanTokenAmount: '抵押數量',
  loanAmount: '貸款金額',
  loanMortgage: '貸款成數',
  loanRate: '利率',
  loanInterest: '利息',
  marketValue: '市值',
  APR: '年化報酬率',
  waiting: '待媒合',
  repay: '已還款',
  breach: '違約',
  isCancel: '取消',
  loaning: '貸款中',
  approve: '申請前請先解鎖',
  more: '大於',
  less: '小於',
  invest: '投資',
  noRecord: '無相關紀錄',
  day: '天',
  hour: '時',
  min: '分',
  sec: '秒',
  contract: '合約',
  expired: '過期',
  buffer: '緩衝期',
  due: '到期',
  status: '合約狀態',
  withdraw: '收回',
  edit: '編輯',
  cancel: '取消',
  payback: '還款',
  apply: '申請',
  editWarning: '編輯將會【取消】該筆訂單並再次【建立】新訂單',
  editSubWarning: '(取消及建立皆須支付礦工費)',
  // calculator
  swapCalculateTitle: '{token1} 兌 {token2}',
  change: '兌換',
  amount: '數量',
  dollar: '元',
  dataUpdated: '資料更新於',
  calculate: '計算',
  // community
  refererAmount: '我的直推人數',
  communityAmount: '我的社區人數',
  // ADT
  adt: 'ADT 天使分潤',
  holding: '持有量',
  claimable: '可領量',
  claim: '領取',
  // gas fee
  gasPage: 'Gas 補助',
  date: '日期',
  from: '起',
  to: '訖',
  applyAddress: '申請人地址',
  search: '搜尋',
  total: '總計',
  subsidy: '補貼',
  subsidyTotal: '補助總額',
  send: '送出',
  // warning
  warning: '重要提示',
  warningRegistry: '1. 進行操作前請先註冊，若未註冊會導致交易失敗。',
  warningBorrow: '2. 貸款清單中抵押品市值顯示僅供參考，借貸雙方不可做爲決策時的唯一依據，本平台不負責吿知抵押品正確市值之責任！',
  warningDeposit: '2. 投資清單中抵押品市值顯示僅供參考，借貸雙方不可做爲決策時的唯一依據，本平台不負責吿知抵押品正確市值之責任！',
  warningCalculate: '該抵押品之市值，請於「EGT換算器」中計算。',
  toRegistry: '前往註冊',
  toCalculator: '前往 EGT 換算器',
  agree: '我已了解上述說明，同意打勾。',
  confirm: '確認',
  // toasted
  loginFirst: '請先登入',
  installMetamask: '請安裝 MetaMask',
  changeMainnet: '請切換到幣安智能鏈',
  changeETH: '請切換至以太主網',
  connectionBreak: 'MetaMask 連接中斷，請重新登入',
  changeWallet: '已切換連接錢包',
  waitApprove: '解鎖中，請稍後',
  waitRegistry: '註冊中，請稍後',
  waitClaim: '提取中，請稍後',
  waitBuying: '進行中，請稍後',
  txSend: '交易已送出',
  userRefuse: '使用者拒絕連線',
  addressHasUsed: '此地址已註冊',
  errorOccured: '發生錯誤',
  USDTUnderBalance: 'USDT 餘額不足',
  TBTUnderBalance: 'TBT 餘額不足',
  underBalance: '餘額不足',
  gameOpening: '彩金池開獎中',
  cannotGetGas: '無法計算礦工費',
  renewGetGas: 'WebSocket 連線中斷，請重整網頁以獲取最新的預估礦工費用',
  renew: 'WebSocket 連線中斷，請重整網頁',
  waitGetData: '正在從鏈上獲取資訊，請稍後',
  noReward: '尚無收益可領取',
  cannotGetEthValue: '取得 ETH 市值失敗',
  cannotGetData: '取得資料失敗',
  selectTx: '請選擇要補助之交易',
  txError: '交易發生錯誤',
  agreeFirst: '請勾選同意',
  cannotGetMarketValue: '無法計算市值',
  // other
  cannotFindPage: '找不到網頁',
  webConstructing: '網站建置中',
  webConstructingDescription: '很抱歉您所造訪的網頁正在建置中，功能尚未開放',
}